const btns = [...document.querySelectorAll('.navbar a.nav-link')];

const url = window.location.pathname;

btns.forEach(btn => {
  let currentButton = btn.dataset.page;

  if (url.includes(currentButton) || url === '/' && currentButton === "index") {
    btn.classList.add('active');
  } else {
    btn.classList.remove('active');
  }
});
